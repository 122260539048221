<template>
  <div class="admin-container" v-if="loaded">
    <h1>Vyberte stavbu</h1>
    <h1 v-if="!data.length" class="mt-10 text-center">
      Žádné stavby nenalezeny ..
    </h1>
    <v-row class="mt-12">
      <v-col v-for="building in data" :key="building.id" cols="12" md="4">
        <v-card class="elevation-1 pa-8 pa-0" tile>
          <v-card-title style="font-size: 16px"
            >{{ building.name }}
          </v-card-title>
          <v-card-subtitle
            >{{ building.city }} |
            {{ building.completion_date }}
            <div class="d-block">
              <strong>PM:</strong> {{ building.project_manager.full_name }}
            </div>
            <div class="d-block">
              <strong>Investor:</strong> {{ building.investor.full_name }}
            </div>
          </v-card-subtitle>
          <v-card-actions style="margin-top: -10px">
            <v-row>
              <v-col class="text-right">
                <v-btn
                  x-small
                  height="32"
                  tile
                  color="primary"
                  @click="selectBuilding(building)"
                  >Vybrat</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import authHandler from "../api/authHandler";
import bus from "../plugins/bus";
import buildingService from "../api/buildingService";

export default {
  data() {
    return {
      loaded: false,
      dashboard: null,
      data: [],
    };
  },
  async created() {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      if (building) {
        this.$router.push({ name: "Timeline" });
        return;
      }
      await this.$store.dispatch("getCurrentUser");
      await this.getBuildings();
      this.loaded = true;
    } catch (error) {
      if (!authHandler(error)) this.$router.push({ name: "Login" });
    }
  },
  methods: {
    selectBuilding(building) {
      localStorage.setItem("building", JSON.stringify(building));
      bus.$emit("select-building", building);
      bus.$emit("snackbar", { text: `Stavba "${building.name}" vybrána` });
      this.$router.push({ name: "Timeline" });
    },
    async getBuildings() {
      try {
        bus.$emit("processing");
        const data = await buildingService.index();
        console.log(data);
        this.data = data;
        bus.$emit("processing", false);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style></style>
